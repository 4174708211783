import axios from 'axios';
import CONFIG from '../config';
import {message} from 'antd';

window.counter = 0;

function handleLoading() {
  document.querySelector('body').setAttribute('loading', window.counter > 0 ? 'true' : 'false')
}

export function getAxios() {
  if (!window['custom_axios']) {
    window['custom_axios'] = axios.create({
      baseURL: `${CONFIG.apiEndpoint}`,
    });

    window['custom_axios'].interceptors.request.use(function(config) {
      window.counter++;
      handleLoading();

      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    window['custom_axios'].interceptors.response.use(response => {
      window.counter--;
      handleLoading();

      return response;
    }, error => {
      window.counter--;
      handleLoading();

      try {
        console.log('DEBUG error', error);
        const errorMessage = error.response.data.error.message;
        if (errorMessage && errorMessage.indexOf('::') < 0) {
          setTimeout(() => {
            message.destroy();
            message.error(errorMessage, 5);
          });
        }
      } catch (e) {
        // No handle
      }

      // if (error.response.status === 403) {
      //   localStorage.removeItem('token');
      //   localStorage.removeItem('user');
      //
      //   window.location.href = '/auth/init';
      //   return;
      // }

      return Promise.reject(error);
    });
  }

  return window['custom_axios'];
}

export function getOpenAi() {
  if (!window['custom_openAi']) {
    window['custom_openAi'] = axios.create({
      baseURL: `${CONFIG.apiEndpoint}`,
    });

    window['custom_openAi'].interceptors.request.use(function(config) {
      window.counter++;
      handleLoading();

      config.headers.Authorization = `Bearer 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`;

      return config;
    });

    window['custom_openAi'].interceptors.response.use(response => {
      window.counter--;
      handleLoading();

      return response;
    }, error => {
      window.counter--;
      handleLoading();

      try {
        console.log('DEBUG error', error);
        const errorMessage = error.response.data.error.message;
        if (errorMessage && errorMessage.indexOf('::') < 0) {
          setTimeout(() => {
            message.destroy();
            message.error(errorMessage, 5);
          });
        }
      } catch (e) {
        // No handle
      }

      // if (error.response.status === 403) {
      //   localStorage.removeItem('token');
      //   localStorage.removeItem('user');
      //
      //   window.location.href = '/auth/init';
      //   return;
      // }

      return Promise.reject(error);
    });
  }

  return window['custom_openAi'];
}
