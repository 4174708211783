import {AuthLayout} from '../../components/layouts/AuthLayout';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

export default function DashboardPage(props) {
  const history = useHistory();
  useEffect(() => {
  }, []);
  return (
      <AuthLayout pageTitle={'MOHA Server AWS'}>
        <div className={'pageWrapper'}>
          <br/><br/><br/>
          <center style={{color: 'white'}}>Click menu records on the left sidebar to start</center>
        </div>
      </AuthLayout>
  );
}
