import React, {useEffect, useState} from 'react';
import {Link, NavLink, useHistory, useLocation} from 'react-router-dom';
import styles from './AuthLayout.module.scss';
import {FaChartBar, FaPenNib, FaUserFriends} from 'react-icons/fa';
import {Dropdown, message, Space} from 'antd';
import {BiSolidDownArrow} from 'react-icons/bi';
import {BsFillBuildingFill, BsFillPatchQuestionFill} from 'react-icons/bs';
import {HiOutlineSpeakerphone} from 'react-icons/hi';
import {IoNewspaperOutline} from 'react-icons/io5';
import {FaAnglesLeft, FaAnglesRight} from 'react-icons/fa6';
import useStrapiRest from '../../hooks/useStrapiRest';
import {formatNumberWithCommas} from '../../helper';

const logo = require('../../assets/images/logo-moha.webp');

export function AuthLayout(props) {
  const [valid, setValid] = useState(false);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState('company');
  const [isSidebarOpen, setIsSidebarOpen] = useState('yes');
  const [actionsUser, dataUser] = useStrapiRest('users');

  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    try {
      const adminRole = localStorage.getItem('adminRole');
      const adminUser = localStorage.getItem('user');

      if (adminRole) {
        setRole(adminRole);
      }

      if (adminUser) {
        setUser(JSON.parse(adminUser));
      }
    } catch (e) {}

    clearTimeout(window.refUser);
    window.refUser = setTimeout(() => {
      actionsUser.getItem('me');
    }, 500);
  }, [location]);

  useEffect(() => {
    if (dataUser?.detail) {
      localStorage.setItem('user', JSON.stringify(dataUser?.detail));
    }
  }, [dataUser?.detail])

  // useEffect(() => {
  //   if (typeof isSidebarOpen === 'boolean') {
  //     localStorage.setItem('isSidebarOpen', isSidebarOpen ? 'yes' : 'no');
  //   }
  // }, [isSidebarOpen]);

  // useEffect(() => {
  //   const isSidebarOpen = localStorage.getItem('isSidebarOpen') === 'yes';
  //   setIsSidebarOpen(isSidebarOpen);
  // }, [])

  useEffect(() => {
    document.body.setAttribute('sidebar', isSidebarOpen ? 'open' : 'close');
  }, [isSidebarOpen])

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setValid(true);
      try {
        setUser(JSON.parse(localStorage.getItem('user')));
      } catch (e) {
      }
    } else {
      localStorage.setItem('redirectUrl', window.location.href);
      window.location.href = '/auth/init';
    }
  }, []);

  if (!valid) {
    return (
        <div>
          <center>Loading data...</center>
        </div>
    );
  }

  const menuLinks = [
    // {
    //   icon: <FaChartBar size={15}/>,
    //   to: '/dashboard',
    //   name: 'Dashboard',
    // },
    {
      icon: <FaUserFriends size={15}/>,
      to: '/servers',
      name: 'Servers',
    },
    // {
    //   icon: <FaUserFriends size={15}/>,
    //   to: '/saved-lessons',
    //   name: 'Saved lessons',
    // },
    // {
    //   icon: <FaUserFriends size={15}/>,
    //   to: '/saved-notes',
    //   name: 'Saved notes',
    // },
    // {
    //   icon: <FaUserFriends size={15}/>,
    //   to: '/saved-screens',
    //   name: 'Saved screens',
    // },
    // {
    //   icon: <FaPenNib size={15}/>,
    //   to: '/notes',
    //   name: 'Notes',
    // },
  ];

  function handleLogout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    history.push('/auth/init');
  }

  const onClick = ({key}) => {
    if (key === 'logout') {
      handleLogout();
    } else {
      history.push('/profile');
    }
  };

  const items = [
    // {
    //   label: 'Thông tin cá nhân',
    //   key: 'profile',
    // },
    {
      label: 'Đăng xuất',
      key: 'logout',
    },
  ];

  return (
      <div className={styles.moduleWrapper}>
        <div className={'wrapper'}>
          <div className="sidebar">
            <div className="top">
              <div className="logo">
                <Link to={'/'}>
                  {isSidebarOpen ? 'MOHA Server' : 'AWS'}
                </Link>
              </div>
              <div className="links">
                {menuLinks.map((item, index) => {
                  return (
                      <div key={item.to} className={'link'}>
                        <NavLink href={item.to} to={item.to}
                                 activeClassName="selected">
                          {item.icon}
                          <div className="name">
                            {item.name}
                          </div>
                        </NavLink>
                      </div>
                  );
                })}
              </div>
            </div>
            <div className="bottom">
              <div className="copyright">MOHA</div>
            </div>
          </div>
          <div className="content">
            <div className="pageHeading">
              <div className={'pageTitle'}>
                {/*<div className="sidebarActions">*/}
                {/*  {isSidebarOpen && (*/}
                {/*      <div onClick={() => {*/}
                {/*        setIsSidebarOpen(false)*/}
                {/*      }}><FaAnglesLeft size={15} /></div>*/}
                {/*  )}*/}

                {/*  {!isSidebarOpen && (*/}
                {/*      <div onClick={() => {*/}
                {/*        setIsSidebarOpen(true)*/}
                {/*      }}><FaAnglesRight /></div>*/}
                {/*  )}*/}
                {/*</div>*/}
                <div className="title">{props.pageTitle || 'Page title'}</div>
              </div>
              <div className="right">
                <Dropdown menu={{items, onClick}} trigger={['click']}>
                  <a onClick={(e) => e.preventDefault()}>
                    Hi {user.email} <BiSolidDownArrow size={15}/>
                  </a>
                </Dropdown>
              </div>
            </div>

            {props.children}
          </div>
        </div>
      </div>
  );
}
