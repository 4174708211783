import './App.module.scss';

import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from 'react-router-dom';

import AuthPage from './pages/Auth/AuthPage';
import AuthCallbackPage from './pages/Auth/AuthCallbackPage';
import {useEffect} from 'react';
import {ConfigProvider, Spin} from 'antd';
import DashboardPage from './pages/Dashboard/DashboardPage';
import viVN from 'antd/locale/vi_VN';
import 'dayjs/locale/vi';

import styles from './App.module.scss';
import LoginPage from './pages/Auth/LoginPage';
import {NoAuthLayout} from './components/layouts/NoAuthLayout';
import ProfileUpdatePage from './pages/Profile/ProfileUpdatePage';
import ServersPage from './pages/Servers/ServersPage';

document.addEventListener('keydown', e => {
  if (document.body.getAttribute('loading') === 'true') {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
  }
});

function App() {
  const history = useHistory();
  useEffect(() => {
    console.log('DEBUG history', history);
  }, [history?.location]);

  return (
      <div className={styles.moduleWrapper}>
        <div className="loadingOverlay">
          <div className="content">
            <Spin size="large"/>
          </div>
        </div>

        <ConfigProvider
            locale={viVN}
            theme={{
              components: {
                Button: {
                  colorPrimary: '#000',
                  algorithm: true, // Enable algorithm
                },
                Checkbox: {
                  colorPrimary: '#000',
                  algorithm: true, // Enable algorithm
                },
              },
              // algorithm: theme.darkAlgorithm
            }}>
          <Router>
            <Switch>
              <Redirect exact from={'/'} to={'/servers'}/>

              <Route exact path="/dashboard">
                <DashboardPage/>
              </Route>

              <Route exact path="/profile">
                <ProfileUpdatePage/>
              </Route>

              <Route exact path="/servers">
                <ServersPage/>
              </Route>

              <Redirect exact from={'/auth/logout'} to="/auth/init"/>

              <Route path="/auth/callback">
                <AuthCallbackPage/>
              </Route>

              <Redirect exact from={'/auth'} to="/auth/init"/>
              <Route path="/auth/init">
                <AuthPage/>
              </Route>
              <Route path="/auth/login">
                <NoAuthLayout>
                  <LoginPage/>
                </NoAuthLayout>
              </Route>
            </Switch>
          </Router>
        </ConfigProvider>
      </div>
  );
}

export default App;
