import moment from 'moment';

export function formatDate(dateString) {
  return moment(dateString).format('DD-MM-YYYY')
}

export function mappingRoleToLabel(status) {
  const mapping = {
    'role.company-admin': 'Company admin',
    'role.super-admin': 'Super admin',
  }

  return mapping[status] || 'Không xác định';
}

export function getCurrentUserFromStorage() {
  return JSON.parse(localStorage.getItem('user'))
}

export function copyToClipboard(text) {
  // Create a new text area element
  const textArea = document.createElement('textarea');

  // Set the text content of the text area to the text you want to copy
  textArea.value = text;

  // Append the text area to the document
  document.body.appendChild(textArea);

  // Select the text in the text area
  textArea.select();

  // Execute the copy command to copy the selected text to the clipboard
  document.execCommand('copy');

  // Remove the temporary text area element
  document.body.removeChild(textArea);
}

export function formatNumberWithCommas(number) {
  // Check if the input is a valid number
  if (typeof number !== 'number' || isNaN(number)) {
    return '0';
  }

  // Use toLocaleString to format the number with commas as the thousand separator
  return number.toLocaleString();
}


export function getRegionNameById(regionId) {
  const regionNames = {
    'us-east-1': 'N. Virginia',
    'us-east-2': 'Ohio',
    'us-west-1': 'N. California',
    'us-west-2': 'Oregon',
    'af-south-1': 'Cape Town',
    'ap-east-1': 'Hong Kong',
    'ap-south-1': 'Mumbai',
    'ap-northeast-3': 'Osaka',
    'ap-northeast-2': 'Seoul',
    'ap-southeast-1': 'Singapore',
    'ap-southeast-2': 'Sydney',
    'ap-northeast-1': 'Tokyo',
    'ca-central-1': 'Central',
    'eu-central-1': 'Frankfurt',
    'eu-west-1': 'Ireland',
    'eu-west-2': 'London',
    'eu-south-1': 'Milan',
    'eu-west-3': 'Paris',
    'eu-north-1': 'Stockholm',
    'me-south-1': 'Bahrain',
    'sa-east-1': 'São Paulo',
    'cn-north-1': 'Beijing',
    'cn-northwest-1': 'Ningxia',
    'gov-west-1': 'US-West',
    'gov-east-1': 'US-East'
    // Add more regions as needed
  };

  return regionNames[regionId] || 'Unknown Region';
}
