import styles from './_page.module.scss';
import {Button, Form, Input, message, Select} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import {mappingRoleToLabel} from '../../helper';

const endpoint = _pageConfig.endpoint;
export default function ProfileUpdatePage() {
  const [actions, data] = useStrapiRest(endpoint);
  const [companyActions, companyData] = useStrapiRest('companies');
  const [roleActions, roleData] = useStrapiRest('roles');

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      await roleActions.getItems();
      await companyActions.getItems({limit: 1000});
      await actions.getItems({limit: 1000});
    })();
  }, []);

  useEffect(() => {
    console.log('DEBUG data', data);
  }, [data])

  useEffect(() => {
    if (data.list) {
      form.setFieldsValue({
        ...data.list,
        company_id: data.list.company?.id,
        role_id: data.list.roles[0]?.id
      });
    }
  }, [JSON.stringify(data?.list)]);

  async function onFinish(values) {
    try {
      await actions.updateItem('', values);
      message.success('Update successfully!');
      history.push(`/${endpoint}`);
    } catch (e) {
      console.error(e);
      message.warning('Có lỗi xảy ra!');
    }
  }

  return (
      <AuthLayout pageTitle={'Update account'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <Form
                  form={form}
                  onFinish={onFinish}
                  colon={false}
                  layout="vertical"
                  style={{
                    width: '100%',
                  }}
                  className={'twoColumns'}
              >
                <Form.Item
                    name={'email'}
                    label={'Email'}
                    rules={[
                      {required: true},
                      {
                        type: 'email',
                        message: 'Hãy nhập đúng định dạng email',
                      },
                      {max: 30}
                    ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                    name={'password'}
                    label={'Mật khẩu mới'}
                    rules={[
                      {required: false},
                      {min: 3},
                      {max: 30}
                    ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                    name={'name'}
                    label={'Họ tên'}
                    rules={[
                      {required: true},
                      {min: 3},
                      {max: 30}
                    ]}
                >
                  <Input/>
                </Form.Item>


                <Form.Item
                    name={'phone_number'}
                    label={'SĐT'}
                    rules={[
                      {required: true},
                      {min: 6},
                      {max: 11}
                    ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                    name={'role_id'}
                    label={'Vị trí'}
                    rules={[{required: true}]}
                >
                  <Select disabled={true}>
                    {roleData?.list?.map(item => {
                      return (
                          <Select.Option key={item.id} value={item.id}>{mappingRoleToLabel(item.name)}</Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                    name={'company_id'}
                    label={'Công ty trực thuộc'}
                    rules={[{required: false}]}
                >
                  <Select disabled={true}>
                    {companyData?.list?.map(item => {
                      return (
                          <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                <Form.Item className={'submitRow'}>
                  <Button block type="primary" htmlType="submit">
                    Cập nhật
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
