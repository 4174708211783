import React, { useState, useEffect } from 'react';
import { Select, Button } from 'antd';
import {getCurrentUserFromStorage} from '../helper';

const { Option } = Select;

const DurationSelect = ({ initialStartHour = 0, initialStopHour = 0, onDurationChange, pic }) => {
  const [startHour, setStartHour] = useState(initialStartHour);
  const [stopHour, setStopHour] = useState(initialStopHour);

  const currentUser = getCurrentUserFromStorage();
  const canEdit = currentUser?.id === pic?.id && !!pic;

  useEffect(() => {
    setStartHour(initialStartHour);
    setStopHour(initialStopHour);
  }, [initialStartHour, initialStopHour]);

  const generateHourOptions = () => {
    let options = [
      <Option key={'null'} value={null}>---</Option>
    ];
    for (let i = 0; i <= 24; i++) {
      options.push(<Option key={i} value={i}>{i}:00</Option>);
    }
    return options;
  };

  const onStartHourChange = value => {
    setStartHour(value);
    if (value > stopHour) {
      setStopHour(value);
    }
  };

  const onStopHourChange = value => {
    setStopHour(value);
    if (startHour > value) {
      setStartHour(value);
    }
  };

  const handleSubmit = () => {
    if (onDurationChange) {
      onDurationChange({ startHour, stopHour: stopHour });
    }
  };

  return (
      <div className={'flexCenter'}>
        <Select
            value={startHour}
            onChange={onStartHourChange}
            style={{ width: 80 }}
            disabled={!canEdit}
        >
          {generateHourOptions()}
        </Select>
        <div> to </div>
        <Select
            value={stopHour}
            onChange={onStopHourChange}
            style={{ width: 80 }}
            disabled={startHour === 24 || !canEdit}
        >
          {generateHourOptions()}
        </Select>

        {!!canEdit && (
            <Button onClick={handleSubmit}>Update</Button>
        )}
      </div>
  );
};

export default DurationSelect;
