import {Button, Form, Input, message} from 'antd';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';

import styles from './LoginPage.module.scss';
import {getAxios} from '../../services/api';
import {useHistory} from 'react-router-dom';

export default function LoginPage() {
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('adminRole');
  }, []);

  async function onFinish(values) {
    try {
      const res = await getAxios().post('/api/login', values);
      message.success('Đăng nhập thành công');

      const token = res.data.token;
      const adminUser = res.data.user;
      const adminRole = res.data.user.roles.includes('role.super-admin') ? 'SUPER' : 'COMPANY';
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(adminUser));
      localStorage.setItem('adminRole', adminRole);

      if (adminRole === 'SUPER') {
        history.push('/users');
      } else {
        history.push('/campaigns');
      }
    } catch (e) {
      console.error(e);
      message.error('Email hoặc mật khẩu không hợp lệ');
    }
  }

  return (
      <div className={styles.moduleWrapper}>
        <Helmet>
          <title>Đăng nhập - MOHA Server</title>
        </Helmet>

        <center>
          <h3 style={{
            paddingTop: 0,
            marginTop: 0
          }}>LOGIN</h3>
        </center>

        <Form
            form={form}
            onFinish={onFinish}
            colon={false}
            layout="vertical"
            style={{
              width: '100%',
            }}
        >
          <Form.Item
              name={'email'}
              label={'Email'}
              rules={[
                  {required: true},
                  {
                    type: 'email',
                    message: 'Hãy nhập đúng định dạng cho trường Email',
                  }
              ]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
              name={'password'}
              label={'Mật khẩu'}
              rules={[{required: true}]}
          >
            <Input type={'password'}/>
          </Form.Item>

          <Form.Item style={{
            marginTop: '30px'
          }}>
            <Button block type="primary" htmlType="submit">
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
}
