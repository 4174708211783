import React from 'react';

import styles from './NoAuthLayout.module.scss';

const logo = require('../../assets/images/logo-moha.webp');

export function NoAuthLayout({children, authRequired = true}) {
  return (
      <div className={styles.moduleWrapper}>
        <div className={'wrapper'}>
          <div className="header">
            <div className="logo">
              <img height={'50px'} src={logo} alt="Logo"/>
            </div>
            <div className="title">TẬP ĐOÀN CEO VIỆT NAM GLOBAL</div>
            <div className="right"></div>
          </div>
          <div className="content">
            {children}
          </div>
          <div className="footer">
            <div className="copyright">MOHA</div>
          </div>
        </div>
      </div>
  );
}
