import styles from './_page.module.scss';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Pagination,
  Popconfirm,
  Space,
} from 'antd';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {Fragment, useEffect, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import {formatDate, getCurrentUserFromStorage, getRegionNameById} from '../../helper';
import {MdAttachMoney} from 'react-icons/md';
import {CgDanger} from 'react-icons/cg';
import DurationSelect from '../../components/DurationSelect';
import {GiNightSleep} from 'react-icons/gi';
import {FaRunning} from 'react-icons/fa';

const endpoint = _pageConfig.endpoint;
export default function ServersPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [sortedItems, setSortedItems] = useState([]);
  const [actions, data] = useStrapiRest(endpoint);
  const [actionsCustom, dataCustom] = useStrapiRest('custom');
  const [form] = Form.useForm();
  const currentUser = getCurrentUserFromStorage();

  useEffect(() => {
    if (!data?.list) {
      return;
    }

    let sortData = data?.list.map(item => {
      return {
        ...item,
        isCostMoney: ((item.currentStatus.toUpperCase() === 'RUNNING' || item.currentStatus.toUpperCase() === 'AVAILABLE') || item.type.toUpperCase() === 'LIGHTSAIL') ? 1 : 0
      }
    });
    sortData = _.orderBy(sortData, ['isCostMoney']).reverse();

    setSortedItems(sortData);
  }, [data?.list])

  useEffect(() => {
    (async () => {
      await actions.getItems({
        pagination: {pageSize, page: currentPage},
        populate: '*'
      });
    })();
  }, []);

  async function handleDelete(id) {
    const agree = window.confirm('Are you sure?');
    if (!agree) {
      return;
    }

    await actions.deleteItem(id);
    message.success('Xóa thành công');

    await actions.getItems({
      pagination: {pageSize, page: 1}
    });
  }

  async function handleFetchAll() {
    try {
      const customGetResponse = await actionsCustom.customGet('fetchAllInstances');
      console.log('DEBUG customGetResponse', customGetResponse);

      await actions.getItems({
        pagination: {pageSize, page: currentPage},
        populate: '*'
      });

      message.success('Loaded latest items!')
    } catch (e) {
      console.log('DEBUG e', e);
    }
  }

  function renderMoneyStatusIcon(item) {
    if (item.currentStatus.toUpperCase() === 'RUNNING' || item.currentStatus.toUpperCase() === 'AVAILABLE' || item.type.toUpperCase() === 'LIGHTSAIL') {
      return <MdAttachMoney color={'red'} size={25} />
    }
  }

  function renderCurrentStatusIcon(item) {
    if (item.currentStatus.toUpperCase() === 'STOPPED') {
      return <GiNightSleep size={20} color={'#656565'} />
    }

    if (item.currentStatus.toUpperCase() === 'RUNNING') {
      return <FaRunning size={22} color={'green'} />
    }
  }

  return (
      <AuthLayout pageTitle={'List servers'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="commonTable">
                <div className="tableTopActions">
                  <div className="title">List servers</div>
                  <Button onClick={handleFetchAll} type={'primary'}>Fetch all latest information</Button>
                </div>

                {(!data.list || !data.list.length) && (
                    <div className="noContent">
                      No found records.
                    </div>
                )}

                {!!data.list && !!data.list.length && (
                    <Fragment>
                      <table className={'tableContent'}>
                        <thead>
                        <tr>
                          <th className={'no'}>No</th>
                          <th>Type</th>
                          <th>Name</th>
                          <th>Note</th>
                          <th>Status</th>
                          <th>Turn on schedule (VNT)</th>
                          <th>PIC <br/>(can change schedule)</th>
                          {/*<th>Public IP</th>*/}
                          <th>Managers <br/> (can turn on/off)</th>
                          <th>Is Production</th>
                          <th>Region</th>
                          {/*<th>Instance ID</th>*/}
                          <th>Instance Type</th>
                          <th>AWS Account</th>
                          <th className={'actions'}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sortedItems.map((item, index) => {
                          return (
                              <tr key={item.id} style={{
                                backgroundColor: item.pic?.email === currentUser?.email ? '#fff5f5' : ''
                              }}>
                                <td className={'no'}>
                                  {index + 1 + pageSize * (currentPage - 1)}
                                </td>
                                <td>
                                  {item.type}
                                </td>
                                <td>
                                  {item.name}
                                </td>
                                <td>
                                  {item.note}
                                </td>
                                <td>
                                  <div className="flexCenter">
                                    {item.currentStatus?.toUpperCase()} {renderCurrentStatusIcon(item)} {renderMoneyStatusIcon(item)}
                                  </div>
                                </td>
                                <td>
                                  {!!item.isProduction && (
                                      <div>24/24 for honban</div>
                                  )}
                                  {!item.isProduction && (
                                      <DurationSelect
                                          initialStartHour={item.startHour}
                                          initialStopHour={item.stopHour}
                                          pic={item.pic}
                                          onDurationChange={async (newValue) => {
                                            await actionsCustom.customPost('updateTime', {
                                              instanceId: item.instanceId,
                                              startHour: newValue.startHour,
                                              stopHour: newValue.stopHour,
                                            });

                                            await actions.getItems({
                                              pagination: {pageSize, page: currentPage},
                                              populate: '*'
                                            });

                                            message.success('Updated time successfully!');
                                            console.log('DEBUG newValue', newValue);
                                          }}
                                      />
                                  )}
                                </td>
                                <td>
                                  {item.pic?.email?.split('@')[0]}
                                </td>
                                {/*<td>*/}
                                {/*  {item.publicIp}*/}
                                {/*</td>*/}
                                <td>
                                  {item.allowedUsers?.map(item => item.email.split('@')[0]).join(', ')}
                                </td>
                                <td>
                                  {item.isProduction ? <CgDanger color={'red'} size={25} /> : null}
                                </td>
                                <td>
                                  {getRegionNameById(item.region)}
                                </td>
                                {/*<td>*/}
                                {/*  {item.instanceId}*/}
                                {/*</td>*/}
                                <td>
                                  {item.instanceType}
                                </td>
                                <td>
                                  {item.awsAccount?.name}
                                </td>
                                <td className={'actions'}>
                                  {/*{JSON.stringify(item.pic)}*/}
                                  {(item.pic?.id === currentUser.id || item?.allowedUsers.map(item => item.id).includes(currentUser.id)) && (
                                      <div className={'flexCenter'}>
                                        <Button
                                            onClick={async () => {
                                              await actionsCustom.customPost('changeInstanceState', {
                                                instanceId: item.instanceId,
                                                state: 'START',
                                              });

                                              await handleFetchAll();

                                              message.success('Start successfully!');
                                            }}
                                            disabled={item.currentStatus.toUpperCase() !== 'STOPPED'}>Start</Button>
                                        <Button
                                            onClick={async () => {
                                              await actionsCustom.customPost('changeInstanceState', {
                                                instanceId: item.instanceId,
                                                state: 'STOP',
                                              });

                                              await handleFetchAll();

                                              message.success('Stop successfully!');
                                            }}
                                            disabled={item.currentStatus.toUpperCase() !== 'RUNNING' || item.isProduction}>Stop</Button>
                                      </div>
                                  )}
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </table>
                      <div className="tablePagination">
                        <Pagination onChange={async (page) => {
                          setCurrentPage(page);
                          await actions.getItems({
                            page,
                            limit: pageSize,
                          });
                        }} current={currentPage} pageSize={pageSize}
                                    total={data?.listPagination?.total}/>
                      </div>
                    </Fragment>
                )}

              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
